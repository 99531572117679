const CompCardData = [
  {
    title: 'BEST CLOUD BASED HACK',
    pt1: 'Powerpoint Presentation(As Format given by SIH 2022)',
    pt2: 'Prototype for the selected problem statement',
  },
  {
    title: 'BEST CREATIVE HACK',
    pt1: 'KH, 5, Rd No. 5, Sector 15, Gandhinagar, Gujarat 382016',
    pt2: '28th March 2022, Monday',
  },
  {
    title: 'BEST CLOUD BASED HACK',
    pt1: 'Powerpoint Presentation(As Format given by SIH 2022)',
    pt2: 'Prototype for the selected problem statement',
  },
  {
    title: 'BEST CREATIVE HACK',
    pt1: 'KH, 5, Rd No. 5, Sector 15, Gandhinagar, Gujarat 382016',
    pt2: '28th March 2022, Monday',
  },
  {
    title: 'BEST CLOUD BASED HACK',
    pt1: 'Powerpoint Presentation(As Format given by SIH 2022)',
    pt2: 'Prototype for the selected problem statement',
  },
  {
    title: 'BEST CREATIVE HACK',
    pt1: 'KH, 5, Rd No. 5, Sector 15, Gandhinagar, Gujarat 382016',
    pt2: '28th March 2022, Monday',
  },
  {
    title: 'BEST CLOUD BASED HACK',
    pt1: 'Powerpoint Presentation(As Format given by SIH 2022)',
    pt2: 'Prototype for the selected problem statement',
  },
  {
    title: 'BEST CREATIVE HACK',
    pt1: 'KH, 5, Rd No. 5, Sector 15, Gandhinagar, Gujarat 382016',
    pt2: '28th March 2022, Monday',
  },
  {
    title: 'BEST CLOUD BASED HACK',
    pt1: 'Powerpoint Presentation(As Format given by SIH 2022)',
    pt2: 'Prototype for the selected problem statement',
  },
  {
    title: 'BEST CREATIVE HACK',
    pt1: 'KH, 5, Rd No. 5, Sector 15, Gandhinagar, Gujarat 382016',
    pt2: '28th March 2022, Monday',
  },
];

export default CompCardData;
